import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  NextPage,
} from 'next';
import { client } from '../lib/api/apolloClient';
import { HOME_QUERY } from '../lib/api/queries';
import { HomeJumbo } from '../lib/components';
import { MyHead } from '../lib/components/myHead';
import { SectionPicker } from '../lib/components/sections/sectionPicker';
import { getAllFaqs } from '../lib/utils/faqHelper';

const Home: NextPage = ({ page }: any) => {
  const handleScrollDown = () => {
    document.getElementById('home-content').scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <>
      <MyHead
        title={page.seoTitle}
        description={page.seoDescription}
        ogTitle={page.ogTitle ?? page.seoTitle}
        ogDescription={page.ogDescription ?? page.seoDescription}
        ogImage={page.ogImage?.url}
        hidePage={page.hidePage}
        excludeLinks={page.excludeLinks}
        faqs={getAllFaqs(page.sections)}
      />
      <HomeJumbo
        title={page.title}
        image={{
          desktopMedia: page.desktopMedia,
          desktopVideo: page?.desktopVideo,
          mobileMedia: page.mobileMedia,
          mobileVideo: page?.mobileVideo,
        }}
        onScrollDownTapped={handleScrollDown}
      />

      <div id='home-content'>
        <SectionPicker sections={page.sections} />
      </div>
    </>
  );
};

export default Home;

export const getServerSideProps: GetServerSideProps = async ({
  req,
}: GetServerSidePropsContext) => {
  try {
    const response = await client.query({
      query: HOME_QUERY,
    });

    const page = response.data.home;

    return { props: { page: page ?? {} } };
  } catch (e) {}

  return { props: {} };
};
